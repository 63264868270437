import React, { useState, useContext, useRef, useEffect } from 'react';
import { css } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Flex, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { N0, N100, N200, N500 } from '@atlaskit/theme/colors';

import { LoadableLazy, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useSpaceDetail, useSpaceHomepageId } from '@confluence/space-utils';
import {
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
	BREADCRUMBS_EXPERIENCE,
	BREADCRUMBS_DROPDOWN_EXPERIENCE,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';

import { preloadBreadcrumbsDropdown } from './preloadBreadcrumbsDropdown';
import { getIsTextTruncated, buildContentPath } from './breadcrumbsHelpers';

const BreadcrumbsDropdownContent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsDropdownContent" */ './BreadcrumbsDropdownContent'
			)
		).BreadcrumbsDropdownContent,
});

const BreadcrumbsPlaceholder = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsPlaceholder" */ './BreadcrumbsPlaceholder'
			)
		).BreadcrumbsPlaceholder,
});

const separatorStyles = css({
	color: token('color.text.subtle', N200),
	pointerEvents: 'none',
	flexShrink: '0',
	marginRight: token('space.050', '4px'),
	marginLeft: token('space.050', '4px'),
});

const hideSeparatorStyles = css({
	[media.below.xs]: {
		display: 'none',
	},
});

const breadcrumbsStyles = xcss({
	minWidth: '24px', // needed for responsive breadcrumbs
	alignItems: 'center',
	flex: '0 1 auto',
	overflow: 'hidden',
});

const fadedSpaceNameTextStyles = css({
	maskImage: 'linear-gradient(to right, black 60%, transparent 100%)',
	WebkitMaskImage: 'linear-gradient(to right, black 60%, transparent 100%)',
});

const spaceNameTextStyles = css({
	textOverflow: 'ellipsis',
	width: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '100%',
	font: token('font.body'),
});

const noEllipsisStyles = css({
	textOverflow: 'unset',
});

const spaceNameBreadcrumbStyles = css({
	display: 'inline-block',
	cursor: 'pointer',
	flexShrink: 0,
	width: '100%',
	borderRadius: '4px',
	border: 'transparent',
	marginRight: token('space.075', '6px'),
	backgroundColor: token('color.background.neutral.subtle', N0),
	padding: `${token('space.075', '6px')} ${token('space.100', '8px')}`,
	color: token('color.text.subtlest', N100),
	'&:hover': {
		textDecoration: 'underline',
		color: token('color.text.subtle', N200),
	},
	'&:focus': {
		color: token('color.text.subtle', N200),
	},
	'&:active': {
		color: token('color.text', N500),
	},
});

const spaceNameBreadcrumbDropdownButtonStyles = css({
	maxWidth: '240px',
	[media.below.md]: {
		maxWidth: '92px',
	},
	width: 'unset',
	marginRight: token('space.0', '0px'),
});

const currentPageBreadcrumbStyles = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: token('font.heading.xsmall'),
	marginLeft: token('space.100', '8px'),
	marginRight: token('space.075', '6px'),
	minWidth: '24px',

	[media.below.xs]: {
		display: 'none',
	},
});

type BreadcrumbsWithSpaceNameDropdownProps = {
	contentTitle?: string;
	spaceKey: string;
	contentId?: string;
	testId?: string;
	showInlineRename?: boolean;
	shouldDisableMoveButton?: boolean;
};

export const BreadcrumbsWithSpaceNameDropdown = ({
	contentTitle,
	spaceKey,
	contentId,
	testId = 'breadcrumbs-with-space-name-dropdown',
	showInlineRename = false,
	shouldDisableMoveButton = false,
}: BreadcrumbsWithSpaceNameDropdownProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isBreadcrumbsPopupOpen, setIsBreadcrumbsPopupOpen] = useState(false);
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { data: spaceData, error } = useSpaceDetail(spaceKey);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const breadcrumbsButtonTextRef = useRef<HTMLDivElement | null>(null);
	const [spaceNameIsTruncated, setSpaceNameIsTruncated] = useState(false);
	const spaceHomepageId = useSpaceHomepageId(spaceKey);
	const { push } = useRouteActions();

	useEffect(() => {
		// whenever the width of the space name breadcrumbs changes, check if it is truncated to apply the fade css
		const resizeObserver = new ResizeObserver(() => {
			setSpaceNameIsTruncated(getIsTextTruncated(breadcrumbsButtonTextRef));
		});

		const currentBreadcrumb = breadcrumbsButtonTextRef.current;

		if (currentBreadcrumb) {
			resizeObserver.observe(currentBreadcrumb);
		}

		return () => {
			if (currentBreadcrumb) {
				resizeObserver.unobserve(currentBreadcrumb); // Use the stored reference
			}
			resizeObserver.disconnect();
		};
	}, [spaceKey]);

	// Note don't check for loading as when refreshing data we will get data={...} and loading=true
	// In this case if we display the placeholder it will flash before the data is displayed
	// We should continue render with existing data and then update when the new data is available
	if (error || !spaceData) {
		return (
			<>
				{error && (
					<ErrorDisplay error={error}>
						<ExperienceFailure name={BREADCRUMBS_EXPERIENCE} error={error} />
					</ErrorDisplay>
				)}
				<BreadcrumbsPlaceholder />
			</>
		);
	}
	const spaceName = spaceData.name || spaceData.alias || spaceKey;

	const contentTitleToRender =
		contentTitle === '' ? intl.formatMessage(i18n.untitledPage) : contentTitle;
	const shouldOpenDropdown = contentTitleToRender || showInlineRename;
	const shouldShowCurrentContentTitle = contentTitleToRender && !showInlineRename;

	const spaceOverviewPath = buildContentPath({
		contentType: 'overview',
		query: spaceHomepageId
			? { [SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY]: spaceHomepageId }
			: undefined,
		spaceAlias: spaceData.alias,
		spaceKey,
	});

	const handleSpaceNameBreadcrumbClick = () => {
		if (shouldOpenDropdown) {
			if (!isBreadcrumbsPopupOpen) {
				experienceTracker.start({
					name: BREADCRUMBS_DROPDOWN_EXPERIENCE,
				});

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'opened',
						actionSubject: 'breadcrumbsDropdown',
						source: 'breadcrumbs',
					},
				}).fire();
			}

			setIsBreadcrumbsPopupOpen(!isBreadcrumbsPopupOpen);
		} else {
			push(spaceOverviewPath);
		}
	};

	const popupContent = () => {
		return (
			<BreadcrumbsDropdownContent
				spaceKey={spaceKey}
				contentId={contentId}
				closeDropdownOnClickOfBreadcrumbItem={closeDropdownOnClickOfBreadcrumbItem}
				spaceOverviewPath={spaceOverviewPath}
				shouldDisableMoveButton={shouldDisableMoveButton}
				contentTitle={contentTitleToRender}
			/>
		);
	};

	const closeDropdownOnClickOfBreadcrumbItem = () => {
		setIsBreadcrumbsPopupOpen(false);
	};

	const preloadBreadcrumbsDropdownOnSpaceNameHover = () => {
		void preloadBreadcrumbsDropdown({ spaceKey, contentId });
	};

	return (
		<Flex xcss={breadcrumbsStyles} testId={testId}>
			<Popup
				isOpen={isBreadcrumbsPopupOpen}
				onClose={() => setIsBreadcrumbsPopupOpen(false)}
				placement="bottom-start"
				content={popupContent}
				trigger={(triggerProps) => (
					<button
						css={[
							spaceNameBreadcrumbStyles,
							shouldOpenDropdown && spaceNameBreadcrumbDropdownButtonStyles,
						]}
						onClick={handleSpaceNameBreadcrumbClick}
						onMouseEnter={preloadBreadcrumbsDropdownOnSpaceNameHover}
						aria-label="Breadcrumbs"
						{...triggerProps}
						{...ssrPlaceholderIdProp}
					>
						<Flex alignItems="center" gap="space.100">
							{shouldOpenDropdown && (
								<ChevronDownIcon label="" LEGACY_size="medium" color={token('color.icon.subtle')} />
							)}
							<div
								ref={breadcrumbsButtonTextRef}
								css={[
									spaceNameTextStyles,
									shouldOpenDropdown && noEllipsisStyles,
									shouldOpenDropdown && spaceNameIsTruncated && fadedSpaceNameTextStyles,
								]}
							>
								{spaceName}
							</div>
						</Flex>
					</button>
				)}
			/>
			{shouldOpenDropdown && (
				<div css={[separatorStyles, !showInlineRename && hideSeparatorStyles]}>/</div>
			)}
			{shouldShowCurrentContentTitle && (
				<div css={currentPageBreadcrumbStyles}>{contentTitleToRender}</div>
			)}
			<ExperienceSuccess name={BREADCRUMBS_EXPERIENCE} />
		</Flex>
	);
};

const i18n = defineMessages({
	untitledPage: {
		id: 'breadcrumbs.dropdown.untitled-page.default-title',
		defaultMessage: 'Untitled',
		description: 'Title for an untitled draft page, draft blogpost, or live page for breadcrumbs',
	},
});
